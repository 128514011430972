import React, { Component } from "react";
import { Tooltip, Popover, Whisper, Button, ButtonToolbar } from "rsuite";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import { Row, Col, Image, Modal, Form } from "react-bootstrap";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import Card from "../../../components/Card";

// img
// import shap1 from '../../../assets/images/shapes/01.png'
import shap2 from "../../../assets/images/shapes/02.png";
// import shap3 from '../../../assets/images/shapes/03.png'
// import shap4 from '../../../assets/images/shapes/04.png'
// import shap5 from '../../../assets/images/shapes/05.png'
// import shap6 from '../../../assets/images/shapes/06.png'

// services importation
import account from "../../../restApiService/account";
// import depot from '../../../restApiService/depot'
import auth from "../../../restApiService/auth";
import sponsor from "../../../restApiService/sponsors";
import role from "../../../restApiService/role";

const currentUserMpspg = JSON.parse(localStorage.getItem("userInfo"));
const currentUserBusinessId = currentUserMpspg?.businessId;

class admin_list extends Component {
  state = {
    item: [],
    entityData: [],
    entityDataTmp: [],
    selectEntityData: [],
    depotList: [],
    roleList: [],
    roleName: "Administrateur global",
    actionType: "",
    selectEntityID: 0,
    show: false,
    show1: false,
    show2: false,
    show3: false,
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let item = { ...this.state.item };
    item[name] = value;
    if (name === "roleId") {
      let role = this.state.roleList.filter(
        (item) => item.id === Number(value)
        // (item) => item.name === 'Administrateur global',
      );
      console.log("this.state.roleList", this.state.roleList);
      console.log("role", role);
      this.setState({ roleName: role[0].name });
    }
    this.setState({ item });
  };

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  handleClose1 = () => this.setState({ show1: false });
  handleShow1 = () =>
    this.setState({ item: {}, show1: true, actionType: "add" });

  handleClose2 = () => this.setState({ show2: false });
  // const handleShow2 = () => setShow2(true);

  handleClose3 = () => this.setState({ show3: false });

  getEntityData = (token) => {
    console.log(token);
    sponsor
      .sponsorList(token)
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          entityData: response.data,
          entityDataTmp: response.data,
        });

        if (response.data.length > 0) {
          this.setState({ selectEntityData: this.state.entityData[0] });
        }
      })
      .catch((error) => console.log("first"));
  };

  saveHandledData = async () => {
    const { token } = this.props.currentUserInfo;
    let item = { ...this.state.item };
    const promiseVar = toast.loading("Traitement en cours...");
    if (this.state.actionType === "add") {
      await sponsor
        .sponsorCreate(item, token)
        .then((response) => {
          this.componentDidMount();
          this.handleClose1();
          toast.update(promiseVar, {
            render: "Opération éffectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) =>
          toast.update(promiseVar, {
            render: "Une erreur est susvenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        );
    } else {
      delete item["createdAt"];
      delete item["updatedAt"];

      sponsor
        .sponsorUpdate(item.id, item, token)
        .then((response) => {
          this.componentDidMount();
          this.setState({
            selectEntityData: item,
            show2: false,
            actionType: "",
          });
          toast.update(promiseVar, {
            render: "Opération éffectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) =>
          toast.update(promiseVar, {
            render: "Une erreur est susvenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        );
    }
  };

  selectEntity = (action, item) => {
    console.log("item", item);
    if (action === "read") {
      this.setState({
        selectEntityData: item,
        makeAction: false,
        actionType: "",
      });
    } else {
      this.setState({ item: item, show2: true, actionType: "update" });

      setTimeout(() => {
        console.log("item text", item);
      }, 200);
    }
  };

  handleOpenPwdModal = (id) => {
    // this.setState({ show3: true, selectEntityID: id });
    // "admin/affiliations/target/:id_parent/livraisons";
    this.props.history.push("/admin/affiliations/target/" + id + "/livraisons");
  };

  updatedUserPassword = () => {
    let item = { ...this.state.item };
    let passwordData = {
      new_password: item.new_password,
    };
    auth
      .updateUserPasswordByAdmin(this.state.selectEntityID.UserId, passwordData)
      .then((req) => {
        this.handleClose3();
      });
  };

  removeItem = (id) => {
    const promiseVar = toast.loading("Traitement en cours...");
    account
      .deleteAccount(id)
      .then((response) => {
        this.componentDidMount();
        // this.setState({ selectEntityData: this.state.entityData[0] })
        toast.update(promiseVar, {
          render: "Opération éffectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) =>
        toast.update(promiseVar, {
          render: "Une erreur est susvenue",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      );
  };

  componentDidMount() {
    const { token } = this.props.currentUserInfo;
    this.getEntityData(token);
  }

  render() {
    const {
      item,
      entityData,
      selectEntityData,
      depotList,
      roleList,
      roleName,
    } = this.state;
    const triggerRef = React.createRef();
    const close = () => triggerRef.current.close();
    const pwdTooltip = (
      <Tooltip>
        <i>Liste des livraisons</i>
      </Tooltip>
    );
    const delSpeaker = (
      <Popover
        title={
          "Vous êtes sûr de supprimer " +
          selectEntityData.first_name +
          " " +
          selectEntityData.last_name +
          " ?"
        }
      >
        <p className="text-right">
          <button className="ent-btn ent-btn-default mr-1" onClick={close}>
            Non
          </button>
          <button
            className="ent-btn ent-btn-default bg-red-l"
            onClick={() => {
              this.removeItem(item.id ?? selectEntityData.id);
              close();
            }}
          >
            Supprimer
          </button>
        </p>
      </Popover>
    );
    return (
      <>
        <div>
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      Liste des parrainages ({entityData?.length}){" "}
                    </h4>
                  </div>
                  <div>
                    <button
                      className="st-btn-md st-bg-yellow text-black p-h-2 f-w-600 rnd-btn mb-4"
                      onClick={() => this.handleShow1("add")}
                    >
                      <i className="btn-inner">
                        <svg
                          width="25"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                      </i>
                      <span>Ajouter un parrain</span>
                    </button>
                    <Modal show={this.state.show1} onHide={this.handleClose1}>
                      <Modal.Header closeButton>
                        <Modal.Title>Nouveau parain</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row>
                          <Col lg="12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Civilité</Form.Label>
                            </Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              className="mb-3"
                              name="civility"
                              onChange={this.handleChange}
                            >
                              <option>Sélectionnez...</option>
                              <option value="M">M.</option>
                              <option value="Mme">Mme</option>
                              <option value="Mlle">Mlle</option>
                            </Form.Select>
                          </Col>
                          <Col lg="6">
                            <Form.Group className="mb-3">
                              <Form.Label>Nom</Form.Label>
                              <Form.Control
                                type="text"
                                name="last_name"
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6">
                            <Form.Group className="mb-3">
                              <Form.Label>Prénom</Form.Label>
                              <Form.Control
                                type="text"
                                name="first_name"
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="12">
                            <Form.Group className="mb-3">
                              <Form.Label>Contact</Form.Label>
                              <Form.Control
                                type="text"
                                name="contact"
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="12">
                            <Form.Group className="mb-3">
                              <Form.Label>Code</Form.Label>
                              <Form.Control
                                type="text"
                                name="code"
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Status</Form.Label>
                            </Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              className="mb-3"
                              name="status"
                              onChange={this.handleChange}
                            >
                              <option></option>
                              <option value={1}>Activé</option>
                              <option value={0}>Bloqué</option>
                            </Form.Select>
                          </Col>
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="st-btn-md st-bg-yellow text-black f-w-600 p-h-2"
                          onClick={this.saveHandledData}
                        >
                          Enregistrer
                        </button>
                      </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.show2} onHide={this.handleClose2}>
                      <Modal.Header closeButton>
                        <Modal.Title>Editier un parrain</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group className="mb-3">
                          <Form.Label>Nom</Form.Label>
                          <Form.Control
                            type="text"
                            name="last_name"
                            value={item.last_name}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Prénom</Form.Label>
                          <Form.Control
                            type="text"
                            name="first_name"
                            value={item.first_name}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>code</Form.Label>
                          <Form.Control
                            type="text"
                            name="code"
                            value={item.code}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Contact</Form.Label>
                          <Form.Control
                            type="text"
                            name="contact"
                            value={item.contact}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>status</Form.Label>
                        </Form.Group>
                        <Form.Select
                          aria-label="Default select example"
                          className="mb-3"
                          value={item.status}
                          name="status"
                          onChange={this.handleChange}
                        >
                          <option></option>
                          <option value={1}>Activé</option>
                          <option value={0}>Bloqué</option>
                        </Form.Select>
                        <button
                          className="st-btn-md st-bg-yellow text-black p-h-2 f-w-600 rnd-btn mb-4 w-100"
                          onClick={this.saveHandledData}
                        >
                          Enregistrer les modifications
                        </button>
                      </Modal.Body>
                    </Modal>
                    <Modal show={this.state.show3} onHide={this.handleClose3}>
                      <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="text-center">
                          <svg
                            width="164"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.334 2.75H7.665C4.644 2.75 2.75 4.889 2.75 7.916V16.084C2.75 19.111 4.635 21.25 7.665 21.25H16.333C19.364 21.25 21.25 19.111 21.25 16.084V7.916C21.25 4.889 19.364 2.75 16.334 2.75Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.6889 11.9999C10.6889 13.0229 9.85986 13.8519 8.83686 13.8519C7.81386 13.8519 6.98486 13.0229 6.98486 11.9999C6.98486 10.9769 7.81386 10.1479 8.83686 10.1479H8.83986C9.86086 10.1489 10.6889 10.9779 10.6889 11.9999Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.6919 12H17.0099V13.852"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14.1816 13.852V12"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <Form.Group
                            className="mb-4 mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>
                              Entrez le nouveau mot de passe
                            </Form.Label>
                            <Form.Control
                              type="password"
                              name="new_password"
                              autocomplete="new-password"
                              onChange={this.handleChange}
                            />
                          </Form.Group>

                          <button
                            className="st-btn-md st-bg-yellow text-black p-h-2 f-w-600 rnd-btn mb-4 w-100"
                            onClick={this.updatedUserPassword}
                          >
                            Modifier le mot de passe
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  <div className="table-responsive">
                    <table
                      id="user-list-table"
                      className="table table-striped"
                      role="grid"
                      data-toggle="data-table"
                    >
                      <thead>
                        <tr className="ligth">
                          <th>Nom</th>
                          <th>Contacts</th>
                          <th>Code</th>
                          <th>Status</th>
                          <th>Création</th>
                          <th min-width="100px">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entityData.map((item, idx) => (
                          <tr key={idx}>
                            <td>{item.first_name + " " + item.last_name}</td>
                            <td>{item.contact}</td>
                            <td>{item.code}</td>
                            <td>
                              {item.status === 1 && (
                                <span className="badge bg-success">Actif</span>
                              )}
                              {item.status === 0 && (
                                <span className="badge bg-danger">
                                  Désactivé
                                </span>
                              )}
                            </td>
                            <td>
                              <Moment format="DD/MM/YYYY">
                                {item.createdAt}
                              </Moment>
                            </td>
                            <td>
                              <div className="flex align-items-center list-user-action">
                                <Whisper
                                  placement="top"
                                  trigger="hover"
                                  controlId="control-id-hover-enterable"
                                  speaker={pwdTooltip}
                                  enterable
                                >
                                  <button
                                    className="btn btn-sm btn-icon"
                                    onClick={() =>
                                      this.handleOpenPwdModal(item.code)
                                    }
                                  >
                                    <svg
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      {" "}
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M14.7379 2.76175H8.08493C6.00493 2.75375 4.29993 4.41175 4.25093 6.49075V17.2037C4.20493 19.3167 5.87993 21.0677 7.99293 21.1147C8.02393 21.1147 8.05393 21.1157 8.08493 21.1147H16.0739C18.1679 21.0297 19.8179 19.2997 19.8029 17.2037V8.03775L14.7379 2.76175Z"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>{" "}
                                      <path
                                        d="M14.4751 2.75V5.659C14.4751 7.079 15.6231 8.23 17.0431 8.234H19.7981"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>{" "}
                                      <path
                                        d="M14.2882 15.3584H8.88818"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>{" "}
                                      <path
                                        d="M12.2432 11.606H8.88721"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>{" "}
                                    </svg>
                                  </button>
                                </Whisper>{" "}
                                <button
                                  className="btn btn-sm btn-icon"
                                  onClick={() => {
                                    this.selectEntity("update", item);
                                  }}
                                >
                                  <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M15.1655 4.60254L19.7315 9.16854"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </button>{" "}
                                <Whisper
                                  placement="top"
                                  trigger="click"
                                  ref={triggerRef}
                                  controlId="control-id-hover-enterable"
                                  speaker={delSpeaker}
                                  enterable
                                >
                                  <button
                                    className="btn btn-sm btn-icon"
                                    onClick={() =>
                                      this.selectEntity("read", item)
                                    }
                                  >
                                    <svg
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      stroke="currentColor"
                                    >
                                      <path
                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M20.708 6.23975H3.75"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </button>
                                </Whisper>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {entityData.length === 0 && (
                      <p className="text-center mt-2">Aucune donnée trouvée</p>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserInfo: state.userInfoReducer.addedUserInfos,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(admin_list));
