import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import {
  Input,
  InputGroup,
  Whisper,
  Tooltip,
  Modal,
  Drawer,
  DateRangePicker,
} from "rsuite";
import { bindActionCreators } from "redux";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";
import Empty from "../../../../components/Empty/Empty";

// store
import {
  NavbarstyleAction,
  getDirMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  SchemeDirAction,
  ColorCustomizerAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../../../../store/setting/setting";

import account from "../../../../restApiService/account";
import delivery from "../../../../restApiService/delivery";
import deliver from "../../../../restApiService/auth";
import wallet from "../../../../restApiService/wallet";
import transaction from "../../../../restApiService/transaction";
import packages from "../../../../restApiService/packages";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Index extends Component {
  state = {
    item: null,
    providerList: [],
    dataList: [],
    entityDataTmp: [],
    selectedItem: [],
    itemDetail: [],
    detailModalIsOpen: false,
  };

  fetchProviderList(code) {
    const { token } = this.props.currrentUser;
    account
      .getUserByReferralCode(code, token)
      .then((req) => {
        this.setState({ providerList: req.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  coursesList(code) {
    const { token } = this.props.currrentUser;
    packages.packageByProviderParentCode(code, token).then((req) => {
      this.setState({
        dataList: req.data,
        entityDataTmp: req.data,
      });
    });
  }

  detailModal = (item) => {
    console.log(item);
    this.setState({
      detailModalIsOpen: true,
      selectedItem: item,
      itemDetail: item,
    });
  };

  handleSearch = (name, event) => {
    let type = name;
    console.log("name", name);

    if (name == "status") {
      if (Number(event.target.value) == 11) {
        this.setState({
          dataList: this.state.entityDataTmp,
        });
      } else if (
        Number(event.target.value) !== 11 &&
        [2, 3, 4, 5].includes(Number(event.target.value))
      ) {
        this.setState({
          dataList: this.state.entityDataTmp.filter(
            (item) => item.status == Number(event.target.value)
          ),
        });
      }

      if (Number(event.target.value) === 0) {
        console.log("event.target.value 0 : ", event.target.value);
        this.setState({
          dataList: this.state.entityDataTmp.filter(
            (item) => item.status == null
          ),
        });
      }
      if (Number(event.target.value) == 1) {
        console.log("event.target.value 1 : ", event.target.value);
        this.setState({
          dataList: this.state.entityDataTmp.filter(
            (item) => item.status == null && item.isCurrent == 1
          ),
        });
      }
      if (Number(event.target.value) === 22) {
        console.log("event.target.value 2 : ", event.target.value);
        this.setState({
          dataList: this.state.entityDataTmp.filter(
            (item) => item.isCurrent == 2
          ),
        });
      }
    } else if (name == "code") {
      if (event.target.value.length >= 2) {
        this.setState({
          dataList: this.state.entityDataTmp.filter((item) =>
            item[type].toLowerCase().includes(event.target.value.toLowerCase())
          ),
        });
      }
      if (event.target.value.length === 0) {
        this.setState({
          dataList: this.state.entityDataTmp,
        });
      }
    } else if (name == "provider") {
      console.log(event.target.value);
      if (event.target.value != 0) {
        this.setState({
          dataList: this.state.entityDataTmp.filter(
            (item) => item?.livo_delivery?.provider.id == event.target.value
          ),
        });
      }
      if (event.target.value == 0) {
        this.setState({
          dataList: this.state.entityDataTmp,
        });
      }
    } else if (name == "deliver") {
      if (event.target.value.length >= 2) {
        this.setState({
          dataList: this.state.entityDataTmp.filter(
            (item) =>
              item?.livo_delivery?.deliver?.first_name
                .toLowerCase()
                .includes(event.target.value.toLowerCase()) ||
              item?.livo_delivery?.deliver?.last_name
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
          ),
        });
      }
      if (event.target.value.length === 0) {
        this.setState({
          dataList: this.state.entityDataTmp,
        });
      }
    } else if (name == "createdAt") {
      if (event.target.value.length >= 2) {
        this.setState({
          dataList: this.state.entityDataTmp.filter((item) =>
            item.createdAt
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
          ),
        });
      }
      if (event.target.value.length === 0) {
        this.setState({
          dataList: this.state.entityDataTmp,
        });
      }
    }
  };

  handleChangeStatus = (event) => {
    this.setState({ item: event.target.value });
  };

  handlerStatus = async (itemData) => {
    const { token } = this.props.currrentUser;
    let data = {
      isCurrent: 0,
      status: Number(this.state.item),
    };

    await packages
      .packageStatusChange(itemData.id, data, token)
      .then((res) => {
        if (
          Number(this.state.item) === 5 ||
          Number(this.state.item) === 4 ||
          Number(this.state.item) === 2
        ) {
          wallet
            .getWalletByDeliverId(this.state.selectedItem?.livoDeliverId, token)
            .then((res) => {
              let walletItem = {
                solde: Number(res.data[0].solde) - 300,
                delivery: Number(res.data[0].delivery) - 1,
              };
              let transactionItem = {
                amount: 300,
                transaction_type: 2,
                livoWalletId: res.data[0].id,
                livoUserId: res.data[0].livoUserId,
              };
              wallet.updateWallet(res.data[0].id, walletItem);
              transaction.createTransaction(transactionItem);
            })
            .catch((err) => {
              // console.error('wallet error', err);
            });
          this.componentDidMount();
          toast.success("Opération éffectuée avec succès", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        toast.error("Opération a échoué", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
    // await this.getPackageByDeliveryId(this.state.selectedItem?.livoDeliverId);
    // console.log("itemData", itemData);
    await this.getPackageByDeliveryId(itemData?.livoDeliveryId);
  };

  getPackageByDeliveryId = (DeliveryId) => {
    const { token } = this.props.currrentUser;
    // console.warn('DeliveryId', DeliveryId);
    packages
      .packageByDeliveryId(DeliveryId, token)
      .then((res) => {
        // this.setState({ DeliveryPackageList: res.data });
        // console.warn('DeliveryPackageList', res.data);
        const allAreFinished = this.allAreFinished(res.data);
        if (allAreFinished) {
          // console.warn('DeliveryPackageList', res.data);
          // console.warn('allAreFinished', allAreFinished);
          this.currentDeliveryIsOver(DeliveryId);
        } else {
          setTimeout(() => {
            this.setState({ dataSending: false });
            this.props.navigation.push("Accueil");
          }, 1000);
          this.componentDidMount();
        }
      })
      .catch((error) => {});
  };
  getPackageEngine = (data) => {
    const { token } = this.props.currrentUser;
    const providerId = this.props.match.params.id_parent;
    let dataTmp = {
      startDate: data[0],
      endDate: data[1],
    };
    console.log(token);
    packages
      .packageByProviderParentCodeAndDateRange(providerId, dataTmp, token)
      .then((res) => {
        this.setState({
          dataList: res.data,
          entityDataTmp: res.data,
        });
      })
      .catch((error) => {});
  };

  currentDeliveryIsOver = (deliveryId) => {
    const { token } = this.props.currrentUser;
    let data = {
      isCurrent: 0,
    };
    delivery
      .deliveryUpdate(deliveryId, data, token)
      .then((response) => {
        this.componentDidMount();
        setTimeout(() => {
          this.setState({ detailModalIsOpen: false });
        }, 1000);
      })
      .catch((error) => {});
  };

  allAreFinished(array) {
    const result = array.every((element) => {
      if (element.isCurrent === array[0].isCurrent) {
        return true;
      }
    });

    return result;
  }

  handleChangeDate = (event) => {
    const { token } = this.props.currrentUser;
    if (event != null) {
      const startDate = moment(event[0]).format("YYYY-MM-DD");
      const endDate = moment(event[1]).format("YYYY-MM-DD");
      console.log(startDate, endDate);
      const dateArray = [startDate, endDate];
      this.getPackageEngine(dateArray);
    } else {
      this.coursesList(this.props.match.params.id_fournisseur);
    }
  };

  componentDidMount() {
    this.coursesList(this.props.match.params.id_parent);
    this.fetchProviderList(this.props.match.params.id_parent);
  }

  render() {
    const { providerList } = this.state;
    const hideModal = () => {
      this.setState({ detailModalIsOpen: false });
    };
    return (
      <>
        <Row>
          <Col md="12" lg="12">
            <Row>
              <Col md="12" lg="12">
                <div className="overflow-hidden card">
                  <div className="flex-wrap card-header d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="mb-2 card-title">
                        Listes des livraisons affiliées{" "}
                        <strong className="text-success">
                          {this.props.match.params.id_parent}
                        </strong>{" "}
                        ({this.state.dataList.length})
                      </h4>
                    </div>
                  </div>
                  <div className="setting-box d-flex p-4 justify-content-between">
                    <div className="form-item mr-4 w-25">
                      <label>Code</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(event) => this.handleSearch("code", event)}
                      />
                    </div>
                    <div className="form-item mr-4 w-25">
                      <label>Fournisseurs</label>
                      <select
                        className="form-control"
                        onChange={(event) =>
                          this.handleSearch("provider", event)
                        }
                      >
                        <option value={0}>Tout</option>
                        {providerList.map((provider, index) => (
                          <option value={provider.id} key={index}>
                            {provider.first_name + " " + provider.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-item mr-4 w-25">
                      <label>Livreurs</label>
                      <input
                        className="form-control"
                        onChange={(event) =>
                          this.handleSearch("deliver", event)
                        }
                      />
                    </div>
                    <div className="form-item mr-4">
                      <label>Status</label>
                      <select
                        className="form-control"
                        onChange={(event) => this.handleSearch("status", event)}
                      >
                        <option value={11}>Tout</option>
                        <option value={0}>En attente</option>
                        <option value={22}>En pause</option>
                        <option value={1}>En cours</option>
                        <option value={4}>Colis refusé</option>
                        <option value={2}>Annulé</option>
                        <option value={5}>Livré</option>
                      </select>
                    </div>
                    <div className="form-item">
                      {/* <label>Date de création</label> */}
                      {/* <input
                        type="date"
                        className="form-control"
                        onChange={(event) =>
                          this.handleSearch("createdAt", event)
                        }
                      /> */}
                      <DateRangePicker
                        placeholder="Sur quelle période ?"
                        // disabledDate={allowedMaxDays(365)}
                        onChange={this.handleChangeDate}
                        format="yyyy-MM-dd"
                        className="delivery-page-date mb-4 w-50"
                        placement="left"
                      />
                    </div>
                  </div>
                  <div className="p-0 card-body">
                    <div className="mt-4 table-responsive">
                      <table
                        id="basic-table"
                        className="table mb-0 table-striped"
                        role="grid"
                      >
                        <thead>
                          <tr>
                            <th className="border-0">ID</th>
                            <th className="border-0">Code</th>
                            <th className="border-0">Fournisseurs</th>
                            <th className="border-0">Livreur</th>
                            <th className="border-0">Status</th>
                            <th className="border-0">Date de création</th>
                            <th className="border-0"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dataList.map((data, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td> {data.code} </td>
                              <td>
                                {data.livo_delivery?.provider
                                  ? data?.livo_delivery?.provider?.first_name +
                                    " " +
                                    data?.livo_delivery?.provider?.last_name
                                  : "-"}
                              </td>
                              <td>
                                {data.livo_delivery?.deliver
                                  ? data?.livo_delivery?.deliver?.first_name +
                                    " " +
                                    data?.livo_delivery?.deliver?.last_name
                                  : "-"}
                              </td>
                              <td>
                                {/* {data.livoDeliverId == null && "-"} */}
                                {data.isCurrent == null && (
                                  <span className="pending">En attente</span>
                                )}
                                {data.isCurrent == 2 && (
                                  <span className="pending">En pause</span>
                                )}
                                {data.isCurrent == 1 && data.status == null && (
                                  <span className="pending">En cours</span>
                                )}
                                {data.status == 2 && (
                                  <span className="failure">Annulé</span>
                                )}
                                {data.status == 3 && (
                                  <span className="failure">
                                    Client Injoignable
                                  </span>
                                )}
                                {data.status == 4 && (
                                  <span className="failure">Colis refusé</span>
                                )}
                                {data.status == 5 && (
                                  <span className="success">Livré</span>
                                )}
                              </td>
                              <td>
                                <Moment format="DD/MM/YYYY">
                                  {data.createdAt}
                                </Moment>
                              </td>
                              <td>
                                <button
                                  className="like-link"
                                  onClick={() => this.detailModal(data)}
                                >
                                  Détails
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {this.state.dataList.length < 1 && <Empty />}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          open={this.state.detailModalIsOpen}
          onClose={hideModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>LIVO ADMIN | Détails de commande</Modal.Title>
          </Modal.Header>
          <Modal.Body className="term">
            <div className="detail-body starter-kit p-4">
              <Accordion>
                <Accordion.Item>
                  <Accordion.Header>
                    <div className="delivery-item-header">
                      <p>
                        <strong>Code : </strong>
                        {this.state.itemDetail?.code}{" "}
                      </p>
                      <p>
                        <strong>
                          Type de colis :{" "}
                          {this.state.itemDetail?.livo_package_type?.title}{" "}
                        </strong>
                      </p>
                      <p>
                        <strong>Statut : </strong>
                        {this.state.itemDetail?.isCurrent == 1 && (
                          <span>En cours</span>
                        )}
                        {this.state.itemDetail?.isCurrent == 2 && (
                          <span>En pause</span>
                        )}
                        {this.state.itemDetail?.status == 2 && (
                          <span>Annulée</span>
                        )}
                        {this.state.itemDetail?.status == 3 && (
                          <span>Client Injoignable</span>
                        )}
                        {this.state.itemDetail?.status == 4 && (
                          <span>Colis refusé</span>
                        )}
                        {this.state.itemDetail?.status == 5 && (
                          <span>Livré</span>
                        )}
                      </p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="delivery-content">
                      <p>
                        <strong>Quantité : </strong>{" "}
                        {this.state.itemDetail?.quantity}
                      </p>
                      <p>
                        <strong>Point de ramassage : </strong>{" "}
                        {this.state.itemDetail?.pick_up_point}{" "}
                      </p>
                      <p>
                        <strong>Point de livraison : </strong>{" "}
                        {this.state.itemDetail?.delivery_point}
                      </p>
                      <p>
                        <strong>Montant des articles : </strong>{" "}
                        {this.state.itemDetail?.amount === 0
                          ? "Non indiqué"
                          : this.state.itemDetail?.amount}
                      </p>
                      <p>
                        <strong>Poids : </strong>{" "}
                        {this.state.itemDetail?.livo_package_weight?.title}
                      </p>
                      <p>
                        <strong>Nom du client : </strong>{" "}
                        {this.state.itemDetail?.recipient_full_name}
                      </p>
                      <p>
                        <strong>Contact : </strong>{" "}
                        {this.state.itemDetail?.recipient_contact}
                      </p>
                      <p>
                        <strong>Livreur en charge : </strong>{" "}
                        {this.state.selectedItem?.livo_delivery?.deliver != null
                          ? this.state.selectedItem?.livo_delivery?.deliver
                              ?.first_name +
                            " " +
                            this.state.selectedItem?.livo_delivery?.deliver
                              ?.last_name
                          : "-"}
                      </p>
                      {this.state.selectedItem?.livoDeliverId != null && (
                        <div className="d-flex align-items-end w-100 mt-3">
                          <div>
                            <p>
                              <strong>
                                Modifier le status de la livraison :{" "}
                              </strong>
                            </p>
                            <p>
                              <select
                                className="form-control mt-3"
                                name="status"
                                onChange={this.handleChangeStatus}
                              >
                                <option value={11}>En cours</option>
                                <option value={22}>En pause</option>
                                <option value={2}>Annulée</option>
                                <option value={3}>Client Injoignable</option>
                                <option value={4}>Colis refusé</option>
                                <option value={5}>Livré</option>
                              </select>
                            </p>
                          </div>
                          <div>
                            <button
                              className="st-btn-md st-bg-yellow text-white f-w-600 w-100 p-h-20"
                              onClick={() =>
                                this.handlerStatus(this.state.selectedItem)
                              }
                            >
                              Appliquer
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currrentUser: state.userInfoReducer.addedUserInfos,
    darkMode: getDarkMode(state),
    customizerMode: getcustomizerMode(state),
    cololrinfomode: getcustomizerinfoMode(state),
    colorprimarymode: getcustomizerprimaryMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
      ColorCustomizerAction,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
