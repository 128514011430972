import React from "react";
import Index from "../views/dashboard/index";
import { Switch, Route } from "react-router-dom";
// user

import AdminList from "../views/dashboard/app/admin-list";
import Affiliations from "../views/dashboard/app/affiliations";
import AdminProfile from "../views/dashboard/app/user-profile";
import AppSettings from "../views/dashboard/app/app-settings";
import Annoncement from "../views/dashboard/app/annoncement";

// app component
import Courses from "../views/dashboard/courses";
import Deliver from "../views/dashboard/deliver";
import Statistiques from "../views/dashboard/statistiques";

import Fournisseurs from "../views/dashboard/Fournisseurs/Fournisseurs";
import OrderFornisseurs from "../views/dashboard/courses/fournisseurs";
import OrderLivreurs from "../views/dashboard/courses/livreurs";
import OrderListForOneParent from "../views/dashboard/courses/livraisons";

import Users from "../views/dashboard/Users/Users";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

const DefaultRouter = () => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Switch>
          <Route path="/admin/dashboard" exact component={Index} />
          {/* user */}

          {/* app main routes */}
          <Route path="/admin/courses" exact component={Courses} />
          <Route path="/admin/livreurs" exact component={Deliver} />
          <Route path="/admin/fournisseurs" exact component={Fournisseurs} />
          <Route
            path="/admin/fournisseurs/courses/:id_fournisseur"
            exact
            component={OrderFornisseurs}
          />
          <Route
            path="/admin/livreurs/courses/:id_livreur"
            exact
            component={OrderLivreurs}
          />
          <Route
            path="/admin/affiliations/target/:id_parent/livraisons"
            exact
            component={OrderListForOneParent}
          />
          <Route path="/admin/statistiques" exact component={Statistiques} />
          <Route path="/admin/users" exact component={Users} />
          <Route path="/admin/affiliations" exact component={Affiliations} />
          <Route path="/admin/admin-list" exact component={AdminList} />
          <Route path="/admin/admin-profile" exact component={AdminProfile} />
          <Route path="/admin/app-settings" exact component={AppSettings} />
          <Route path="/admin/annoncements" exact component={Annoncement} />
          {/* eof */}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default DefaultRouter;
