import axios from "axios";
import config from "./config";

const headersConfig = (token) => {
  const head = { headers: { Authorization: `Bearer ${token}` } };
  return head;
};

export default {
  sponsorList: (token) => {
    // console.log(data);
    return axios.get(
      `${config.URL_END_POINT}/sponsor/list`,
      headersConfig(token)
    );
  },
  sponsorCreate: (data, token) => {
    // console.log(data);
    return axios.post(
      `${config.URL_END_POINT}/sponsor/add`,
      data,
      headersConfig(token)
    );
  },
  sponsorById: (id, token) => {
    return axios.get(
      `${config.URL_END_POINT}/sponsor/${id}`,
      headersConfig(token)
    );
  },
  sponsorUpdate: (id, data, token) => {
    return axios.put(
      `${config.URL_END_POINT}/sponsor/update/${id}`,
      data,
      headersConfig(token)
    );
  },
};
